import React from "react";
import {
    LineChartOutlined,
    FileProtectOutlined,
    ExperimentOutlined,
    TeamOutlined,
    // UserOutlined,
    DesktopOutlined,
    BankOutlined
} from "@ant-design/icons";

const data = {
    main : [
        {
            title : "Dashboard",
            link  : "/kelola/dashboard",
            icon  : <LineChartOutlined style={{fontSize : 18}} />,
            role  : ["admin"]
        }, {
            title : "Laporan",
            link  : "/kelola/laporan",
            icon  : <FileProtectOutlined style={{fontSize : 18}} />,
            role  : ["admin"]
        }
    ],
    exam : [
        {
            title : "Simulasi Ujian",
            link  : "/kelola/simulasi-ujian",
            icon  : <ExperimentOutlined style={{fontSize : 18}} />,
            role  : ["admin"]
        },
        {
            title : "Ujian",
            link  : "/kelola/ujian",
            icon  : <ExperimentOutlined style={{fontSize : 18}} />,
            role  : ["user"]
        },
    ],
    setting : [
        {
            title : "Pengguna",
            link  : "/kelola/pengguna",
            icon  : <TeamOutlined style={{fontSize : 18}} />,
            role  : ["admin"]
        }, {
            title : "Halaman depan",
            link  : "/kelola/halaman-depan",
            icon  : <DesktopOutlined style={{fontSize : 18}} />,
            role  : ["admin"]
        }, {
            title : "Bank",
            link  : "/kelola/bank",
            icon  : <BankOutlined style={{fontSize : 18}} />,
            role  : ["admin"]
        },
        // {
        //     title : "Akun",
        //     link  : "/kelola/akun",
        //     icon  : <UserOutlined style={{fontSize : 18}} />,
        //     role  : ["admin", "user"]
        // }
    ]
}

export default data