import React from 'react';

interface Pages {
    path      : string,
    isPublic  : boolean,
    component : React.LazyExoticComponent<React.FC>,
    role      : string[]
}

let pages : Pages[] = [
    {
        path      : "/daftar",
        isPublic  : true,
        component : React.lazy(() => import('../pages/register')),
        role      : []
    },
    {
        path      : "/kelola/dashboard",
        isPublic  : false,
        component : React.lazy(() => import('../pages/dashboard')),
        role      : ["admin"]
    },
    {
        path      : "/kelola/laporan/:packageName?/:packageId?",
        isPublic  : false,
        component : React.lazy(() => import('../pages/report')),
        role      : ["admin"]
    },
    {
        path      : "/kelola/simulasi-ujian/:packageId?/:examId?",
        isPublic  : false,
        component : React.lazy(() => import('../pages/simulation')),
        role      : ["admin"]
    },
    {
        path      : "/kelola/ujian",
        isPublic  : false,
        component : React.lazy(() => import('../pages/exam')),
        role      : ["user"]
    },
    {
        path      : "/kelola/pengguna",
        isPublic  : false,
        component : React.lazy(() => import('../pages/user')),
        role      : ["admin"]
    },
    {
        path      : "/kelola/halaman-depan",
        isPublic  : false,
        component : React.lazy(() => import('../pages/frontpage')),
        role      : ["admin"]
    },
    {
        path      : "/kelola/bank",
        isPublic  : false,
        component : React.lazy(() => import('../pages/bank')),
        role      : ["admin"]
    },
    {
        path      : "/",
        isPublic  : true,
        component : React.lazy(() => import('../pages/login')),
        role      : []
    }
]

export default pages